import { useWorkspaceState } from "@/coordinator/workspace/state/WorkspaceContext";
import { ActionIntegrationModel, ActionTriggerModel } from "@/feature/action/domain/model/ActionModel";
import { Button } from "@/infrastructure/uikit/components/ui/button";
import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/infrastructure/uikit/components/ui/resizable";
import { useUrlQueryParams } from "@/infrastructure/utility/NavigationUitl";
import { ArrowLeft, Braces, SlidersHorizontal } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Tree, TreeNode } from "react-organizational-chart";
import { useNavigate } from "react-router-dom";
import { ActionPropertyView } from "./ActionPropertyView";
import { EventNodeType } from "./node/EventNodeType";
import { TriggerNodeType } from "./node/TriggerNodeType";
import { ActionDataView } from "@/feature/action/presentation/ActionDataView";
import { VariableViewModel } from "@/feature/variable/presentation/VariableViewModel";
import { FrameViewModel } from "@/feature/frame/presentation/FrameViewModel";
import { ActionViewModel } from "@/feature/action/presentation/ActionViewModel";

export const ActionView: FC = () => {
  const navigate = useNavigate();
  const query = useUrlQueryParams();
  const { actionViewModel, integrationViewModel, frameViewModel, variableViewModel } = useWorkspaceState();
  const [rightPanel, selectRightPanel] = useState<string>("ACTION_TRIGGER_PROPERTY");

  const frameId = query.get("frameId") ?? "";

  const installedActions =
    integrationViewModel.uiState.actionIntegrationsInstalled
      ?.filter((integration) => {
        return !integration.integrationDeprecated;
      })
      ?.map((integration) => {
        return {
          id: integration.integrationId,
          name: integration.integrationName,
          keyType: integration.integrationKeyType,
          version: integration.integrationVersion
        } as ActionIntegrationModel;
      }) ?? [];

  return (
    <div className="flex flex-col w-full bg-background h-full">
      <div className="flex flex-row w-full justify-between items-center h-16 bg-card shadow-md px-4">
        <div className="flex flex-row items-center space-x-2">
          <Button
            onClick={() => {
              actionViewModel.uiAction.getActionTrigger(frameId, "");
              navigate(-1);
            }}
            variant="ghost"
            size="icon"
            className="rounded-full"
          >
            <ArrowLeft className="h-4 w-4" />
          </Button>
          <p>Action of {frameViewModel.uiState.selectedFrame?.name ?? ""}</p>
        </div>
      </div>
      <ResizablePanelGroup direction="horizontal">
        <ResizablePanel defaultSize={80}>
          <div className="overflow-x-auto overflow-y-auto h-full">
            <DndProvider backend={HTML5Backend}>
              <Tree
                label={
                  <EventNodeType
                    name={actionViewModel.uiState.selectedAction?.event}
                    installedActions={installedActions}
                    addActionTrigger={(integrationId: string, name: string, parentId: string | null, then: string) => {
                      actionViewModel.uiAction.addActionTrigger(frameId, integrationId, name, parentId, then);
                    }}
                  />
                }
              >
                <TreeBuilder
                  key={Math.random()}
                  triggers={actionViewModel.uiState.actionTriggerHierarchy?.filter((tr) => !!tr.id) ?? []}
                  actionViewModel={actionViewModel}
                  installedActions={installedActions}
                  activeTrigger={actionViewModel.uiState.selectActionTrigger}
                  addActionTrigger={(integrationId: string, name: string, parentId: string | null, then: string) => {
                    actionViewModel.uiAction.addActionTrigger(frameId, integrationId, name, parentId, then);
                  }}
                  deleteActionTrigger={(triggerId: string) => {
                    actionViewModel.uiAction.deleteActionTrigger(frameId, triggerId);
                  }}
                  selectActionTrigger={(trigger: ActionTriggerModel) => {
                    actionViewModel.uiAction.getActionTrigger(frameId, trigger.id ?? "");
                  }}
                  updateActionTriggerPosition={(triggerId: string, parentId: string, then: string) => {
                    actionViewModel.uiAction.updateActionTriggerPosition(frameId, triggerId, parentId, then);
                  }}
                  updateActionTriggerName={(triggerId: string, name: string) => {
                    actionViewModel.uiAction.updateActionTriggerName(frameId, triggerId, name);
                  }}
                  integrationList={installedActions}
                  upgradeActionTrigger={(triggerId: string, integrationId: string) => {
                    actionViewModel.uiAction.upgradeActionTrigger(frameId, triggerId, integrationId);
                  }}
                />
              </Tree>
            </DndProvider>
          </div>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={20}>
          <>
            <div className="flex flex-row p-2 space-x-2">
              <div className={"w-full"}>
                <RightSidePanelContent
                  variableViewModel={variableViewModel}
                  actionViewModel={actionViewModel}
                  frameViewModel={frameViewModel}
                  frameId={frameId}
                  activePanel={rightPanel}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <Button
                  disabled={!actionViewModel.uiState.selectActionTrigger}
                  onClick={() => {
                    selectRightPanel("ACTION_TRIGGER_PROPERTY");
                  }}
                  variant={rightPanel === "ACTION_TRIGGER_PROPERTY" ? "secondary" : "ghost"}
                  size="icon"
                >
                  <SlidersHorizontal className="h-4 w-4" />
                </Button>
                <Button
                  disabled={!actionViewModel.uiState.selectActionTrigger}
                  onClick={() => {
                    selectRightPanel("ACTION_TRIGGER_DATA");
                  }}
                  variant={rightPanel === "ACTION_TRIGGER_DATA" ? "secondary" : "ghost"}
                  size="icon"
                >
                  <Braces className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};

const TreeBuilder = (props: any) => {
  return (
    <>
      {props.triggers.map((trigger: ActionTriggerModel) => {
        return (
          <TreeNode
            key={Math.random()}
            label={
              <TriggerNodeType
                key={Math.random()}
                name={trigger.name}
                activeTrigger={props.activeTrigger}
                installedActions={props.installedActions}
                addActionTrigger={props.addActionTrigger}
                deleteActionTrigger={props.deleteActionTrigger}
                trigger={trigger}
                selectActionTrigger={props.selectActionTrigger}
                updateActionTriggerPosition={props.updateActionTriggerPosition}
                updateActionTriggerName={props.updateActionTriggerName}
                upgradeActionTrigger={props.upgradeActionTrigger}
                integrationList={props.installedActions}
              />
            }
          >
            {trigger.id ? (
              <TreeBuilder
                actionViewModel={props.actionViewModel}
                triggers={trigger.subTriggers ?? []}
                key={trigger.id}
                activeTrigger={props.activeTrigger}
                installedActions={props.installedActions}
                addActionTrigger={props.addActionTrigger}
                deleteActionTrigger={props.deleteActionTrigger}
                selectActionTrigger={props.selectActionTrigger}
                updateActionTriggerPosition={props.updateActionTriggerPosition}
                updateActionTriggerName={props.updateActionTriggerName}
                upgradeActionTrigger={props.upgradeActionTrigger}
                integrationList={props.installedActions}
              />
            ) : (
              <></>
            )}
          </TreeNode>
        );
      })}
    </>
  );
};

type RightSidePanelContentProps = {
  actionViewModel: ActionViewModel;
  variableViewModel: VariableViewModel;
  frameViewModel: FrameViewModel;
  frameId: string
  activePanel: string | null;
};

const RightSidePanelContent: FC<RightSidePanelContentProps> = (props: RightSidePanelContentProps) => {
  useEffect(() => {
    props.variableViewModel.uiAction.getVariables(props.frameId);
  }, [props.frameId]);
  if (props.activePanel === "ACTION_TRIGGER_PROPERTY") {
    return (
      <ActionPropertyView frameId={props.frameId} actionViewModel={props.actionViewModel} />
    );
  } else if (props.activePanel === "ACTION_TRIGGER_DATA") {
    return (
      <ActionDataView
        frameId={props.frameId} actionViewModel={props.actionViewModel}
        variables={
          props.variableViewModel.uiState.variables?.map((variable) => {
            return { id: variable.key ?? "", text: variable.key ?? "" };
          }) ?? []
        }
      />
    );
  } else {
    return <></>;
  }
};

