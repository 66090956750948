import { Button } from "@/infrastructure/uikit/components/ui/button";
import { Combobox } from "@/infrastructure/uikit/components/ui/combobox";
import { Label } from "@/infrastructure/uikit/components/ui/label";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { RefreshCw } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { ActionViewModel } from "@/feature/action/presentation/ActionViewModel";
import { ActionTriggerDataModel } from "@/feature/action/domain/model/ActionModel";

export type ActionDataViewProps = {
  frameId: string;
  variables: { id: string; text: string }[];
  actionViewModel: ActionViewModel;
};

export const ActionDataView: FC<ActionDataViewProps> = (props: ActionDataViewProps) => {
  const [data, setData] = useState<Map<string, ActionTriggerDataModel> | null>();
  const changeMap = new Map<string, string>();

  useEffect(() => {
    setData(props.actionViewModel.uiState.selectActionTrigger?.data ?? new Map());
  }, [props.frameId, props.actionViewModel.uiState.selectActionTrigger]);

  return (
    <>
      <ScrollArea>
        {data?.size ?? 0 > 0 ? (
          <Button
            variant={"default"}
            className="w-full"
            onClick={() => {
              props.actionViewModel.uiAction.updateActionTriggerData(
                props.frameId,
                props.actionViewModel.uiState.selectActionTrigger?.id ?? "",
                changeMap
              );
              changeMap.clear();
            }}
          >
            <RefreshCw className="h-4 w-4 me-2" />
            Update data
          </Button>
        ) : (
          <></>
        )}
        <div className={"flex flex-col gap-2 pt-4"}>
          {Array.from(data?.keys() ?? []).map((key) => {
            const dataItem = data?.get(key);
            if (!dataItem) return <></>;
            return (
              <ActionTriggerTextFieldDataPickerView
                variables={props.variables}
                key={dataItem.id + Math.random().toString()}
                itemKey={dataItem.key}
                itemValue={dataItem.value}
                onChange={(key, newValue) => {
                  changeMap.set(key, newValue);
                }}
              />
            );
          })}
        </div>
      </ScrollArea>
    </>
  );
};

type ActionTriggerTextFieldDataPickerViewProps = {
  variables: { id: string; text: string }[];
  itemKey: string;
  itemValue: string;
  onChange: (key: string, newValue: string) => void;
};

export const ActionTriggerTextFieldDataPickerView = (props: ActionTriggerTextFieldDataPickerViewProps) => {
  return (
    <>
      <div
        key={props.itemKey + Math.random().toString()}
        className="grid w-full items-center gap-1.5 bg-muted p-2 rounded-lg"
      >
        <Label
          key={props.itemKey + Math.random().toString()}
          title={props.itemKey ?? ""}
          htmlFor={"actionTriggerDataPicker_" + props.itemKey}
        >
          {props.itemKey}
        </Label>
        <Combobox
          key={props.itemKey + Math.random().toString()}
          list={props.variables}
          defaultValue={props.itemValue}
          onSelect={(key: string) => {
            props.onChange(props.itemKey ?? "", key);
          }}
        />
      </div>
    </>
  );
};
