import { gql } from "@apollo/client";

export const GET_ACTIONS_QUERY = gql`
  query actions($frameId: String!) {
    actions(frameId: $frameId) {
      id
      key
      event
      triggers {
        id
        name
        parentId
        keyType
        name
        then
        integrationVersion
        integrationDeprecated
        integrationDeprecatedReason
        properties {
          id
          key
          value
          type
          description
          deprecated
          deprecatedReason
          valuePicker
          valuePickerGroup
          valuePickerOptions
        }
        data {
          id
          key
          value
          type
          deprecated
          deprecatedReason
        }
        events {
          id
          event
          deprecated
          deprecatedReason
        }
      }
    }
  }
`;

export const GET_ACTION_TRIGGER_QUERY = gql`
  query actionTriggerById($frameId: String!, $actionId: String!, $actionTriggerId: String!) {
    actionTriggerById(frameId: $frameId, actionId: $actionId, actionTriggerId: $actionTriggerId) {
      id
      name
      parentId
      keyType
      name
      then
      integrationVersion
      integrationDeprecated
      integrationDeprecatedReason
      properties {
        id
        key
        value
        type
        description
        deprecated
        deprecatedReason
        valuePicker
        valuePickerGroup
        valuePickerOptions
      }
      data {
        id
        key
        value
        type
        deprecated
        deprecatedReason
      }
      events {
        id
        event
        deprecated
        deprecatedReason
      }
    }
  }
`;

export const ADD_ACTION_MUTATION = gql`
  mutation addAction($input: AddActionInput!) {
    addAction(input: $input) {
      id
    }
  }
`;

export const ADD_ACTION_TRIGGER_MUTATION = gql`
  mutation addActionTrigger($input: AddActionTriggerInput!) {
    addActionTrigger(input: $input) {
      id
    }
  }
`;

export const DELETE_ACTION_MUTATION = gql`
  mutation deleteAction($input: DeleteActionInput!) {
    deleteAction(input: $input) {
      id
    }
  }
`;

export const DELETE_ACTION_TRIGGER_MUTATION = gql`
  mutation deleteActionTrigger($input: DeleteActionTriggerInput!) {
    deleteActionTrigger(input: $input) {
      id
    }
  }
`;

export const UPDATE_ACTION_TRIGGER_PROPERTIES_MUTATION = gql`
  mutation updateActionTriggerProperties($input: UpdateActionTriggerPropertiesInput!) {
    updateActionTriggerProperties(input: $input) {
      id
    }
  }
`;

export const UPDATE_ACTION_TRIGGER_DATA_MUTATION = gql`
  mutation updateActionTriggerData($input: UpdateActionTriggerDataInput!) {
    updateActionTriggerData(input: $input) {
      id
    }
  }
`;

export const UPGRADE_ACTION_MUTATION = gql`
  mutation upgradeActionTrigger($input: UpgradeActionTriggerInput!) {
    upgradeActionTrigger(input: $input) {
      id
    }
  }
`;

export const UPDATE_ACTION_TRIGGER_POSITION_MUTATION = gql`
  mutation updateActionTriggerPosition($input: UpdateActionTriggerPositionInput!) {
    updateActionTriggerPosition(input: $input) {
      id
    }
  }
`;

export const UPDATE_ACTION_TRIGGER_NAME_MUTATION = gql`
  mutation updateActionTriggerName($input: UpdateActionTriggerNameInput!) {
    updateActionTriggerName(input: $input) {
      id
    }
  }
`;
