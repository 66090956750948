import { Button } from "@/infrastructure/uikit/components/ui/button";
import { ScrollArea } from "@/infrastructure/uikit/components/ui/scroll-area";
import { RefreshCw } from "lucide-react";
import React, { FC, useEffect, useState } from "react";
import { ActionTriggerPropertyModel } from "../domain/model/ActionModel";
import { ActionViewModel } from "./ActionViewModel";
import { ActionDropdownPickerView } from "./picker/ActionDropdownPickerView";
import { ActionTextFieldPickerView } from "./picker/ActionTextFieldPickerView";
import { ActionTextAreaPickerView } from "./picker/ActionTextAreaPickerView";

export type ActionPropertyViewProps = {
  actionViewModel: ActionViewModel;
  frameId: string;
};

export const ActionPropertyView: FC<ActionPropertyViewProps> = (props: ActionPropertyViewProps) => {
  const [properties, setProperties] = useState<Map<string, ActionTriggerPropertyModel> | null>();
  const changeMap = new Map<string, string>();

  useEffect(() => {
    setProperties(props.actionViewModel.uiState.selectActionTrigger?.properties);
  }, [props.actionViewModel.uiState.selectActionTrigger]);

  if (!props.actionViewModel.uiState.selectActionTrigger?.id) {
    return <></>;
  }
  return (
    <>
      <ScrollArea className="">
        <Button
          variant={"default"}
          className="w-full"
          onClick={() => {
            props.actionViewModel.uiAction.updateActionTriggerProperties(props.frameId, changeMap);
          }}
        >
          <RefreshCw className="h-4 w-4 me-2" />
          Update properties
        </Button>
        <div className={"flex flex-col gap-2 pt-4"}>
          {Array.from(properties?.keys() ?? []).map((key) => {
            const property = properties?.get(key);
            if (!property) return <></>;
            return (
              <ActionTriggerPickerView
                key={props.actionViewModel.uiState.selectActionTrigger?.id + Math.random().toString()}
                property={property}
                value={changeMap.get(property.key ?? "") ?? property.value ?? ""}
                onChange={(key, newValue) => {
                  changeMap.set(key, newValue);
                }}
              />
            );
          })}
        </div>
      </ScrollArea>
    </>
  );
};

type ActionTriggerPickerViewProps = {
  property: ActionTriggerPropertyModel;
  value: string;
  onChange: (key: string, newValue: string) => void;
};
const ActionTriggerPickerView: FC<ActionTriggerPickerViewProps> = (props: ActionTriggerPickerViewProps) => {
  if (props.property.valuePicker === "text-input") {
    return <ActionTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "text-area-input") {
    return <ActionTextAreaPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else if (props.property.valuePicker === "dropdown") {
    return <ActionDropdownPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  } else {
    return <ActionTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  }

  // if (props.property.valuePicker === "text-input") {
  //   return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "text-area-input") {
  //   return <BlockTextAreaPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "number-input") {
  //   return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "color-picker") {
  //   return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "slider") {
  //   return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "combobox-input") {
  //   return <BlockComboboxPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "dropdown") {
  //   return <BlockComboboxPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // } else if (props.property.valuePicker === "toggle") {
  //   return <></>;
  // } else {
  //   return <BlockTextFieldPickerView property={props.property} value={props.value} onChange={props.onChange} />;
  // }
};
