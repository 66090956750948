import { ResultModel } from "@/infrastructure/result/model/ResultModel";
import { useToast } from "@/infrastructure/uikit/components/ui/use-toast";
import { useEffect, useState } from "react";
import { variableRepository } from "../di/VariableComponent";
import { VariableModel, VariableTypeModel } from "../domain/model/VariableModel";

export type VariableUiState = {
  variables: VariableModel[] | null;
  variableTypes: VariableTypeModel[];
  searchQuery: string;
  hotReload: string;
};

export type VariableUiAction = {
  getVariables: (frameId: string) => void;
  addVariable: (frameId: string, key: string, value: string, type: string) => void;
  updateVariable: (frameId: string, id: string, key: string, value: string, type: string) => void;
  deleteVariable: (frameId: string, key: string) => void;
  search: (searchQuery: string) => void;
};

export type VariableViewModel = {
  uiState: VariableUiState;
  uiAction: VariableUiAction;
};

export const useVariableViewModel = () => {
  const [uiState, setUiState] = useState({} as VariableUiState);
  const { toast } = useToast();
  const variableTypes = [
    {
      id: "STRING",
      text: "String",
    } as VariableTypeModel,
    {
      id: "INT",
      text: "Int",
    } as VariableTypeModel,
    {
      id: "LONG",
      text: "Long",
    } as VariableTypeModel,
    {
      id: "FLOAT",
      text: "Float",
    } as VariableTypeModel,
    {
      id: "DOUBLE",
      text: "Double",
    } as VariableTypeModel,
    {
      id: "BOOLEAN",
      text: "Boolean",
    } as VariableTypeModel,
  ];

  const [unFilterVariables, setUnFilterVariables] = useState<VariableModel[]>([]);

  useEffect(() => {
    setUiState((prevState) => {
      return {
        ...prevState,
        variableTypes: variableTypes,
      };
    });
  }, []);

  async function addVariable(frameId: string, key: string, value: string, type: string) {
    const result: ResultModel<string> = await variableRepository.addVariable(frameId, key, value, type);
    if (result.onSuccess) {
      toast({
        description: `${key} has created successfully`,
        variant: "default",
      });
      getVariables(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          activeBlock: undefined,
          hotReload: Math.random().toString(),
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function updateVariable(frameId: string, id: string, key: string, value: string, type: string) {
    const result: ResultModel<string> = await variableRepository.updateVariable(frameId, id, key, value, type);
    if (result.onSuccess) {
      toast({
        description: `${key} has updated successfully`,
        variant: "default",
      });
      getVariables(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          activeBlock: undefined,
          hotReload: Math.random().toString(),
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function deleteVariable(frameId: string, key: string) {
    const result: ResultModel<string> = await variableRepository.deleteVariable(frameId, key);
    if (result.onSuccess) {
      toast({
        description: `${key} has deleted successfully`,
        variant: "default",
      });
      getVariables(frameId);
      setUiState((prevState) => {
        return {
          ...prevState,
          activeBlock: undefined,
          hotReload: Math.random().toString(),
        };
      });
    } else {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  async function getVariables(frameId: string) {
    if (!frameId) return;
    const result: ResultModel<VariableModel[]> = await variableRepository.getVariables(frameId);

    if (result.onSuccess) {
      setUnFilterVariables(result.onSuccess);
      setUiState((prevState) => {
        return {
          ...prevState,
          variables: result.onSuccess ?? [],
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          variables: null,
        };
      });
    }

    if (result.onError) {
      toast({ description: result.onError ?? "", variant: "destructive" });
    }
  }

  function search(searchQuery: string) {
    if (!searchQuery) {
      setUiState((prevState) => {
        return {
          ...prevState,
          searchQuery: searchQuery,
          variables: unFilterVariables,
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          searchQuery: searchQuery,
          variables: unFilterVariables.filter((value) => {
            return value.key?.toLowerCase().includes(searchQuery.toLowerCase());
          }),
        };
      });
    }
  }

  return {
    uiState: uiState,
    uiAction: {
      getVariables,
      addVariable,
      updateVariable,
      deleteVariable,
      search,
    },
  } as VariableViewModel;
};
